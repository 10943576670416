import {
  CREATE_REGISTERATION_DETAILS,
  CREATE_REGISTERATION_DETAILS_FAILURE,
  CREATE_REGISTERATION_DETAILS_SUCCESS,
  GET_REGISTERATION_DETAILS,
  GET_REGISTERATION_DETAILS_SUCCESS,
  GET_REGISTERATION_DETAILS_FAILURE,
} from "./types/types";

export const creatRegisterationDetails = (payload) => ({
  type: CREATE_REGISTERATION_DETAILS,
  payload,
});

export const createRegisterationDetailsSuccess = (payload) => ({
  type: CREATE_REGISTERATION_DETAILS_SUCCESS,
  payload,
});

export const createRegisterationDetailsFailure = (payload) => ({
  type: CREATE_REGISTERATION_DETAILS_FAILURE,
  payload,
});

export const getRegisterationDetails = (payload) => ({
  type: GET_REGISTERATION_DETAILS,
  payload,
});

export const getRegisterationDetailsSuccess = (payload) => ({
  type: GET_REGISTERATION_DETAILS_SUCCESS,
  payload,
});

export const getRegisterationDetailsFailure = (payload) => ({
  type: GET_REGISTERATION_DETAILS_FAILURE,
  payload,
});
