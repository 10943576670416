import {
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAILURE,
  GET_QUOTE,
  GET_QUOTE_SUCCESS,
  GET_QUOTE_FAILURE,
  UPDATE_QUOTE,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAILURE,
} from "../types";

export const createQuote = (payload) => ({
  type: CREATE_QUOTE,
  payload,
});

export const createQuoteSuccess = (payload) => ({
  type: CREATE_QUOTE_SUCCESS,
  payload,
});

export const createQuoteFailure = (payload) => ({
  type: CREATE_QUOTE_FAILURE,
  payload,
});

export const getQuote = () => ({
  type: GET_QUOTE,
});

export const getQuoteSuccess = (payload) => ({
  type: GET_QUOTE_SUCCESS,
  payload,
});

export const getQuoteFailure = (payload) => ({
  type: GET_QUOTE_FAILURE,
  payload,
});

export const updateQuote = (payload) => ({
  type: UPDATE_QUOTE,
  payload,
});

export const updateQuoteSuccess = (payload) => ({
  type: UPDATE_QUOTE_SUCCESS,
  payload,
});

export const updateQuoteFailure = (payload) => ({
  type: UPDATE_QUOTE_FAILURE,
  payload,
});
