import {
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAILURE,
  GET_QUOTE,
  GET_QUOTE_SUCCESS,
  GET_QUOTE_FAILURE,
  UPDATE_QUOTE,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAILURE,
} from "../../action/types";

const INIT_STATE = {
  loading: false,
  quoteDetails: null,
};

export default (state = INIT_STATE, action) => {
  // console.log("REDUCER ", action);
  switch (action.type) {
    case CREATE_QUOTE:
      return { ...state, loading: true };
    case CREATE_QUOTE_SUCCESS:
      return { ...state, loading: false };
    case CREATE_QUOTE_FAILURE:
      return { ...state, loading: false };
    case GET_QUOTE:
      return { ...state, loading: true };
    case GET_QUOTE_SUCCESS:
      return { ...state, loading: false, quoteDetails: action.payload };
    case GET_QUOTE_FAILURE:
      return { ...state, loading: false, quoteDetails: action.payload };
    case UPDATE_QUOTE:
      return { ...state, loading: true };
    case UPDATE_QUOTE_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_QUOTE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
