import {
  CREATE_PROPOSAL_HEALTH,
  CREATE_PROPOSAL_SUCCESS_HEALTH,
  CREATE_PROPOSAL_FAILURE_HEALTH,
} from "./types/types";

export const createProposalHealth = (payload) => ({
  type: CREATE_PROPOSAL_HEALTH,
  payload,
});

export const createProposalSuccessHealth = (payload) => ({
  type: CREATE_PROPOSAL_SUCCESS_HEALTH,
  payload,
});

export const createProposalFailureHealth = (payload) => ({
  type: CREATE_PROPOSAL_FAILURE_HEALTH,
  payload,
});
