//= ===============CREATE REGISTERTION DETAILS=================//

export const CREATE_REGISTERATION_DETAILS = "CREATE_REGISTERATION_DETAILS";
export const CREATE_REGISTERATION_DETAILS_SUCCESS =
  "CREATE_REGISTERATION_DETAILS_SUCCESS";
export const CREATE_REGISTERATION_DETAILS_FAILURE =
  "CREATE_REGISTERATION_DETAILS_FAILURE";

//= ================GET REGISTERATION DETAILS===============//

export const GET_REGISTERATION_DETAILS = "GET_REGISTERATION_DETAILS";
export const GET_REGISTERATION_DETAILS_SUCCESS =
  "GET_REGISTERATION_DETAILS_SUCCESS";
export const GET_REGISTERATION_DETAILS_FAILURE =
  "GET_REGISTERATION_DETAILS_FAILURE";

//= ================ADD VEHICLE DETAILS======================//

export const CREATE_VEHICLE_DETAILS = "CREATE_VEHICLE_DETAILS";
export const CREATE_VEHICLE_DETAILS_SUCCESS = "CREATE_VEHICLE_DETAILS_SUCCESS";
export const CREATE_VEHICLE_DETAILS_FAILURE = "CREATE_VEHICLE_DETAILS_FAILURE";

//= ================GET VEHICLE DETAILS====================//

export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS";
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS";
export const GET_VEHICLE_DETAILS_FAILURE = "GET_VEHICLE_DETAILS_FAILURE";
