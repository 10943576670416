import { all, takeEvery, put } from "redux-saga/effects";

import { GET_QUICK_SUMMARY } from "../../action/types";
import { getquickSummarySuccess, getquickSummaryFailure } from "../../action";
import API from "../../../utils/axios";
import { getQuoteid } from "../../../utils/helper";

function* getQuickSummaryRequest() {
  try {
    const res = yield API.get(
      `api/arogya-sanjeevani/quote/${getQuoteid()}/summary/`
    );
    if (res.data.code === 200) {
      yield put(getquickSummarySuccess(res.data.data));
    } else {
      yield put(getquickSummaryFailure(res));
    }
  } catch (e) {
    yield put(getquickSummaryFailure(e));
  }
}

export function* watchGetQuickSummaryAPI() {
  yield takeEvery(GET_QUICK_SUMMARY, getQuickSummaryRequest);
}

export default function* rootSaga() {
  yield all([watchGetQuickSummaryAPI()]);
}
