import React, { memo } from "react";
import Menu from "@material-ui/core/Menu";
import { Call } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Grid, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";

import Logo from "../../assets/Images/zono_logo.png";
import edgeLogo from "../../assets/Images/Zuno_edge_logo.svg";
import { dashboardHomePageUrl } from "../../utils/helper";

const header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [contactUsPopup, setContactUsPopup] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setContactUsPopup(open);
  };

  const list = () => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        className="text-aqua-9"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", float: "right" }}>
              <Call />
              <Box
                className="header-popup-text"
                sx={{ pl: 1, pr: 1, borderRight: "1px solid var(--aqua-9)" }}
              >
                <Typography
                  variant="span"
                  component="span"
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Toll free number
                </Typography>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  1800 12000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="header-popup-text">
              <Typography
                variant="span"
                component="span"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                Paid landline
              </Typography>
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                022 423 12000
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const handleChange = (e, name) => {
    e.preventDefault();
    if (name === "logout") {
      window.location.href = process.env.REACT_APP_LOGOUT_URL;
      setAnchorEl(null);
    } else if (name === "mydashboard") {
      window.location.href = process.env.REACT_APP_MYDASHBOARD_URL;
      setAnchorEl(null);
    }
  };

  const redirectToDashboard = () => {
    window.location.replace(dashboardHomePageUrl);
  };
  return (
    <div className="headermain header-mq">
      <div className="container header1">
        <div className="row">
          <div className="col-md-3 col-sm-3 col-4">
            <div onClick={redirectToDashboard} role="presentation">
              <img className="img-fluid" src={Logo} alt="" title="" />
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 ml-auto tollfree-header-mediaquery"
            style={{ display: "flex" }}
          >
            <div className="tolfreenumber" style={{ paddingRight: "8px" }}>
              <i className="fa fa-phone" aria-hidden="true" />
              <p>Toll free number</p>
              <a href=" ">1800 12000</a>
            </div>
            {/* </div> */}
            {/* <div className="col-md-1"> */}
            <div className="tolfreenumber">
              <div className="vert" />
            </div>
            {/* </div> */}
            {/* <div className="col-md-2"> */}
            <div className="tolfreenumber2" id="phone2">
              <p>Paid landline</p>
              <a href=" ">022 423 12000</a>
            </div>
          </div>
          <div className="col-sm-6 col-5 col-md-0 header-blank-mediaquery" />
          <div className="col-md-1 col-1 col-sm-1">
            <i
              className="fa fa-bars menuicon hamburger-mediaquery"
              onClick={handleClick}
              aria-hidden="true"
            />
          </div>
          <div className="col-md-1 col-1 col-sm-1">
            <img
              style={{ width: "120px", marginLeft: "-70px" }}
              src={edgeLogo}
              alt=""
              title=""
            />
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={(e) => {
                handleChange(e, "mydashboard");
              }}
            >
              Dashboard
            </MenuItem>
            <MenuItem onClick={handleClose}>My Account</MenuItem>
            <MenuItem
              onClick={(e) => {
                handleChange(e, "logout");
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="container header2">
        <div className="row">
          <div className="col-md-3 col-sm-3 col-4 zunologo-div-mediaquery">
            <div onClick={redirectToDashboard} role="presentation">
              <img className="img-fluid" src={Logo} alt="" title="" />
            </div>
          </div>
          <div
            className="col-md-3 col-sm-3 offset-sm-3 col-4"
            style={{ textAlign: "center", padding: "0px 6px" }}
          >
            <div role="presentation">
              <img
                className="img-fluid edgelogo-img-mediaquery"
                src={edgeLogo}
                alt=""
                title=""
              />
            </div>
          </div>
          <div
            className="col-md-1 col-sm-1 col-1"
            style={{
              alignSelf: "center",
              fontSize: "25px",
              color: "var(--aqua-9)",
            }}
          >
            <i
              onClick={toggleDrawer(true)}
              className="fa fa-phone"
              aria-hidden="true"
            />
            <SwipeableDrawer
              className="contactUsDrawer"
              anchor="top"
              open={contactUsPopup}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              {list()}
            </SwipeableDrawer>
          </div>
          <div
            className="col-md-1 col-3 col-sm-2 hamburger-div-mediaquery"
            style={{
              alignSelf: "center",
              fontSize: "25px",
              textAlignLast: "center",
              color: "var(--aqua-9)",
            }}
          >
            <i
              className="fa fa-bars"
              onClick={handleClick}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

header.propTypes = {};

export default memo(header);
