import React from "react";
import PropTypes from "prop-types";

const Button = ({ type, name, btnClassName, onClick, disabled, style }) => {
  return (
    <>
      <button
        type={type === "button" ? "button" : "submit"}
        className={`button ${btnClassName}`}
        onClick={onClick}
        disabled={disabled}
        style={style ? { margin: 0, color: "var(--aqua-9)" } : {}}
      >
        {name}
      </button>
    </>
  );
};

Button.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.bool,
};

export default Button;
