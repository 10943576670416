import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const InputWithLabel = ({
  id,
  label,
  changeValue,
  type,
  maxLength,
  disabled,
  value,
  multiline,
  rows,
  blurChange,
  onClick,
  classes,
  step,
  required,
  fieldVariant,
  onInput,
}) => {
  const textFieldRef = React.useRef(null);
  React.useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    textFieldRef?.current?.addEventListener("wheel", handleWheel);
    if (!type) {
      // eslint-disable-next-line no-param-reassign
      type = "text";
    }
    return () => {
      console.log(required);
      textFieldRef?.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <div className="custome-input">
      <TextField
        variant={fieldVariant}
        id={id}
        classes={classes}
        label={label}
        multiline={multiline}
        onChange={changeValue}
        inputVariant="outlined"
        type={type}
        rows={rows}
        inputProps={{ maxLength, inputMode: type }}
        disabled={disabled}
        value={value}
        onClick={onClick}
        onBlur={blurChange}
        ref={textFieldRef}
        InputLabelProps={{ style: { color: "#00464f" } }}
        step={step}
        onInput={onInput}
      />
    </div>
  );
};
InputWithLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  changeValue: PropTypes.func,
  blurChange: PropTypes.func,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  onClick: PropTypes.func,
  classes: PropTypes.instanceOf(Object),
  step: PropTypes.string,
  required: PropTypes.bool,
  fieldVariant: PropTypes.string,
  onInput: PropTypes.func,
};
export default InputWithLabel;
