import React from "react";

const premiumNotFound = () => {
  return (
    <>
      <h2 style={{ textAlign: "center" }}>
        We Could Not Found Result for your request.
      </h2>
    </>
  );
};
premiumNotFound.propTypes = {};
export default premiumNotFound;
