import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import { Call } from "@material-ui/icons";
import { Grid, Typography } from "@material-ui/core";
import Logo from "../../assets/Images/zono_logo.png";
// import edgeLogo from "../../assets/Images/Zuno_edge_logo.svg";
// import Logo from "../../assets/Images/edllogo.svg";
import { dashboardHomePageUrl } from "../../utils/helper";

const headeruser = () => {
  const redirectToDashboard = () => {
    window.location.replace(dashboardHomePageUrl);
  };

  const [contactUsPopup, setContactUsPopup] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setContactUsPopup(open);
  };

  const list = () => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        className="text-aqua-9"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Call />
              <Box sx={{ pl: 1, pr: 1 }}>
                <Typography
                  variant="span"
                  component="span"
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  Toll free number
                </Typography>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  1800 12000
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <div className="headermain">
      <div className="container headeruser-mq">
        <div className="row">
          <div className="col-md-8 col-lg-9 col-8">
            <div onClick={redirectToDashboard} role="presentation">
              <img className="img-fluid" src={Logo} alt="" title="" />
            </div>
          </div>
          <div className="col-md-4 col-lg-3 col-4">
            <div className="tolfreenumber">
              <i className="fa fa-phone" aria-hidden="true" />
              <p>Toll free number</p>
              <a href=" ">1800 12000</a>
            </div>
            <div className="mr-3 tollfreenumber-callicon-mq">
              <i
                className="fa fa-phone"
                onClick={toggleDrawer(true)}
                aria-hidden="true"
              />
              <SwipeableDrawer
                className="contactUsDrawer"
                anchor="top"
                open={contactUsPopup}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                {list()}
              </SwipeableDrawer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

headeruser.propTypes = {};

export default headeruser;
