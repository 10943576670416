export const QUOTE_CREATED = "quote_created";
export const PREMIUM = "premium";
export const PROPOSAL_DETAILS = "proposal_details";
export const SUMMARY = "summary";
export const PED = "ped";
export const CHOOSE_PLAN = "choose_plan";
export const SELECTED_PLAN = "selected_plan";
export const QUICK_SUMMARY = "quick_summary";
export const NOMINEE_DETAILS = "nominee_details";
export const CONTACT_DETAILS = "contact_details";
export const DISCOUNT_DETAILS = "discount_details";
export const PROPOSAL_SUMMARY = "proposal_summary";
export const POLICY_GENERATED = "policy_generated";
export const POLICY_ISSUED = "policy_issued";
export const PAYMENT_IN_PROGRESS = "payment_in_progress";
export const RENEWAL_KYC_VERIFICATION_PROGRESS = "KYC Verification In Progress";
export const RENEWAL_KYC_VERIFICATION_REJECTED = "KYC Verification Failed";
export const QC_COMPLETED = "QC Completed";
export const QC_REJECT = "QC reject";
export const QC_PENDING = "QC pending";
export const QC_AUTO_REJECTED = "qc auto rejected";
export const IIB_UPDATE = "IIB Data Saved";
export const ADD_INFO_RAISED = "Add Info Raised";
export const ADD_INFO_RESOLVED = "Add Info Resolved";
export const NSTP_CASE = "nstp_case";
export const PAYMENT_DONE = "payment_done";
export const CONSENT_ACCEPTED = "consent_accepted";
export const PAYMENT_BY_AGENT = "payment_by_agent";
export const UW_REVIEW = "UW Review";
export const ADDITIONAL_INFO_RAISED = "Additional Information Raised ";
export const ADDITIONAL_INFO_RESOLVED = "Additional Information Resolved";
export const MED_APPOINMENT_PENDING_AUTO = "Medical Appointment Pending (Auto)";
export const MED_APPOINMENT_BOOK_AUTO = "Medical Appointment Booked (Auto)";
export const MED_APPOINMENT_RE_SCHEDULE_AUTO =
  "Medical Appointment Re-scheduled (Auto)";
export const MED_APPOINMENT_DONE_AUTO = "Medical Appointment Done (Auto)  ";
export const MED_APPOINMENT_DONE_REPORT_UPLOAD_AUTO =
  "Medical Appointment Done and report uploaded (Auto)";
export const MED_APPOINMENT_PENDING_MANUAL =
  "Medical Appointment Pending (Manual)";
export const MED_APPOINMENT_BOOKED_MANUAL =
  "Medical Appointment Booked (Manual)";
export const MED_APPOINMENT_RE_SCHEDULE_MANUAL =
  "Medical Appointment Re-scheduled (Manual)";
export const MED_APPOINMENT_DONE_MANUAL = "Medical Appointment Done (Manual)";
export const MED_APPOINMENT_DONE_REPORT_UPLOADED_MANUAL =
  "Medical Appointment Done and report uploaded (Manual) ";
export const COUNTEROFFER_RAISED = "Counteroffer raised";
export const COUNTEROFFER_ACCEPT_PROPOSAL_CLOSED =
  "Counteroffer Accepted and Proposal Closed";
export const COUNTEROFFER_NOT_ACCEPT_REFUND_INITIATED =
  "Counteroffer Not Accepted and Refund Initiated";
export const COUNTEROFFER_NOT_ACCEPT_REFUND_PROCESSED =
  "Counteroffer Not Accepted and Refund Processed";
export const DECLINED_AND_REFUND_INITIALTED = "Declined and Refund initiated";
export const DECLINED_AND_REFUND_PROCESSED = "Declined and Refund processed";
export const PROPOSAL_CREATED_AND_AUTO_LOADING_APPLIED =
  "Proposal Created and auto Loading Applied";
export const PENDING_FOR_CUSTOMER_ACTION = "Pending for customer Action";
export const PENDING_FOR_PAYMENT = "Pending for payment";
export const TELE_UW_PENDING = "Tele U/W Pending";
export const TELE_UW_REFERRED_TO_SR_UW = "Tele U/W – Referred to SR UW";
export const TELE_UW_REFERRED_TO_REINSURER = "Tele U/W – Referred to Reinsurer";
export const TELE_UW_COMPLETED = "Tele U/W Completed";
export const CUSTOMER_NOT_AVAILABLE_FOR_TELE_UW =
  "Customer Not Available for Tele UW";
export const ACCEPTED_AND_CLOSED = "Accepted and Closed";
export const ADD_INFO_AND_MEDICAL_PENDING =
  "Additional information raised, Medical Appointment pending";
export const ENTER_VALID_ABHA_NUMBER =
  "Please enter 14 digit valid ABHA number";

export const MIN_COPAY_TOPUP = 1000;
export const MAX_COPAY_TOPUP = 50000;

export const MIN_COPAY_SUPERTOPUP = 1000;
export const MAX_COPAY_SUPERTOPUP = 200000;

export const MIN_ORGAN_COVER = 10;
export const MAX_ORGAN_COVER = 50;

export const MIN_DENTAL_COVER = 1000;
export const MAX_DENTAL_COVER = 50000;

export const MIN_VACCINE_COVER = 1000;
export const MAX_VACCINE_COVER = 50000;

export const MIN_MATERNITY_DEDUCTIBLE = 5000;
export const MAX_MATERNITY_DEDUCTIBLE = 50000;

export const PED_WAIVER_12 = "12 Months Waiver Period";
export const PED_WAIVER_24 = "24 Months Waiver Period";
export const NO_PED_WAIVER = "No Waiting Period";

export const PED_WAIVER_PERIOD_MAP = {
  "12 Months Waiver Period": 1,
  "24 Months Waiver Period": 2,
  "No Waiting Period": 3,
};

export const PAYEE_RELATIONS = ["self"];

export const PAYEE_PROPOSER_RELATION = "self";

// Pre Payment Ekyc Status Update
export const KYC_PENDING = "KYC Pending";
export const KYC_COMPLETED_PAYMENT_PENDING = "KYC Completed/Payment Pending";
export const KYC_REJECTED = "KYC Rejected";

export const PRODUCT_NAME = "health topup";

export const toastMessages = {
  linkCopiedToClipBoard: "Link copied to clipboard",
  selectAbhaCheckBox: "Please select ABHA check box",
  deSelectAbhaCheckBox: "Please de-select ABHA check box",
  failedToStoreAbhaDetails: "Failed to store ABHA Details",
  ekycGenerateLinkApiFailed: "Something went wrong while getting the eKYC url",
  ekycStatusApiFailed: "Something went wrong while fetching the eKYC status",
  ekycIncomplete: "Please complete eKYC",
  abhaVerificationSuccess: "ABHA number verified successfully",
  abhaVerificationFailed:
    "Oops! This ABHA ID doesn't seem valid. Please recheck and enter your 14-digit ID again",
  unableToProcessAbhaRequest:
    "Unable to request current process, Please process after some time/ Please process without ABHA ID",
  notAllowedToEdit: "Editing is not allowed",
  notAllowedToDelete: "Deleting is not allowed",
};

export const VALIDATION_MESSAGES = {
  limitExceeded:
    "Apologies! Character length exceeded the limit. Kindly reach us out at 1800 12000 (Toll free).",
};

export const ABHA_NUMBER_REGEX = /^\d{14}$/;
// eslint-disable-next-line no-useless-escape, prettier/prettier
export const ADDRESS_REGEX = /^[A-Za-z0-9,!#\$%\(\)\*\+\.\/:;=\?@\[\]\^_`\|~\-\s]+$/;
export const ERROR_MESSAGES = {
  specialCharsNotAllowedInAddress:
    "{ } ' \" & < > special characters not allowed",
};

export const TOPUP_APIS = {
  QUOTE: "api/health-topup/quote/",
  GET_ALL_MEDICAL_DOCS: "api/health-topup/get-all-docs/",
  SAVE_ABHA: "api/health-topup/store/abha-id/",
  GENERATE_EPORT_FORM: "api/health-topup/generate-e-portability-form",
  VERIFY_OTP: "api/health-topup/otp/verify/",
  DOWNLOAD_EPROPOSAL: "api/health-topup/quote/proposal/e_proposal",
  PROPOSAL: "api/health-topup/proposal/",
  GET_PAYMENT_LINK: "api/health-topup/getpaymentlink/",
  CHECK_PORT_POLICY_DECLINE_STATUS: "api/health-topup/policy-portability/",
  RENEWAL_QUOTE: "api/health-topup/renewal/quote/",
  POLICY_SUMMARY_DATA: "api/health-topup/renewal/policy-summary-data/",
  UPDATE_RENEWAL_POLICY_DETAILS:
    "api/health-topup/renewal/policy-details-update/",
  UPDATE_RENEWAL_MEMBER_DETAILS:
    "api/health-topup/renewal/individual-update-add-delete/",
  DOWNLOAD_RENEWAL_NOTICE: "api/health-topup/renewal/proposal/",
  DOWNLOAD_EPORT_FORM: "e-portability-form/download/",
};

export const POLICY_CASE = {
  new: "new",
  renewal: "renewal",
};
