import {
  CREATE_REGISTERATION_DETAILS,
  CREATE_REGISTERATION_DETAILS_SUCCESS,
  CREATE_REGISTERATION_DETAILS_FAILURE,
  GET_REGISTERATION_DETAILS,
  GET_REGISTERATION_DETAILS_SUCCESS,
  GET_REGISTERATION_DETAILS_FAILURE,
} from "../../action/Car/types/types";

const INITIAL_STATE = {
  registerationDetails: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_REGISTERATION_DETAILS:
      return { ...state, registerationDetails: action.payload };
    case CREATE_REGISTERATION_DETAILS_SUCCESS:
      return { ...state, registerationDetails: action.payload };
    case CREATE_REGISTERATION_DETAILS_FAILURE:
      return { ...state, registerationDetails: action.payload };
    case GET_REGISTERATION_DETAILS:
      return { ...state, registerationDetails: action.payload };
    case GET_REGISTERATION_DETAILS_SUCCESS:
      return { ...state, registerationDetails: action.payload };
    case GET_REGISTERATION_DETAILS_FAILURE:
      return { ...state, registerationDetails: action.payload };
    default:
      return state;
  }
};
