import { all, takeEvery, put, call } from "redux-saga/effects";

import {
  createPostDiseaseSuccess,
  createPostDiseaseFailure,
} from "../../action/Health/last-question";
import { POST_DISEASE_HEALTH } from "../../action/Health/types/types";
import { getQuoteid } from "../../../utils/helper";
import API from "../../../utils/axios";

function* createDiseaseRequest(action) {
  try {
    const quoteID = getQuoteid();
    const res = yield API.post(
      `api/health/quote/pre-existing-desease/${quoteID}/`,
      action.payload.data
    );
    console.log(res);
    yield put(createPostDiseaseSuccess(action.payload));
    yield call(action.payload.callback);
  } catch (error) {
    yield put(createPostDiseaseFailure(error));
  }
}

function* watchPostDisease() {
  yield takeEvery(POST_DISEASE_HEALTH, createDiseaseRequest);
}

export default function* rootSaga() {
  yield all([watchPostDisease()]);
}
