import {
  CREATE_QUOTE_HEALTH,
  CREATE_QUOTE_SUCCESS_HEALTH,
  CREATE_QUOTE_FAILURE_HEALTH,
  GET_QUOTE_HEALTH,
  GET_QUOTE_SUCCESS_HEALTH,
  GET_QUOTE_FAILURE_HEALTH,
} from "./types/types";

export const createQuoteHealth = (payload) => ({
  type: CREATE_QUOTE_HEALTH,
  payload,
});

export const createQuoteFailureHealth = (payload) => ({
  type: CREATE_QUOTE_FAILURE_HEALTH,
  payload,
});

export const createQuoteSuccessHealth = (payload) => ({
  type: CREATE_QUOTE_SUCCESS_HEALTH,
  payload,
});

export const getQuoteHealth = (payload) => ({
  type: GET_QUOTE_HEALTH,
  payload,
});

export const getQuoteSuccessHealth = (payload) => ({
  type: GET_QUOTE_SUCCESS_HEALTH,
  payload,
});

export const getQuoteFailureHealth = (payload) => ({
  type: GET_QUOTE_FAILURE_HEALTH,
  payload,
});
