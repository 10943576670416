import axios from "axios";

import { insuquoteApiUrl } from "./helper";

const api = axios.create({
  baseURL: `${insuquoteApiUrl}`, // "https://edge.edelweisscustomeruat.com/insuquote/",
});

api.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.withCredentials = true;
    tmpConfig.crossDomain = true;
    tmpConfig.defaults = {};
    tmpConfig.defaults.withCredentials = true;
    // tmpConfig.headers.Authorization = `Bearer ${getToken()}`;
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
