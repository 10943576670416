import React, { memo } from "react";
import PropTypes from "prop-types";

const checkBoxWithLabel = ({
  inputClassName,
  labelClassName,
  label,
  checked,
  onChange,
  name,
  value,
  disabled,
  onClick,
  readOnly,
}) => {
  return (
    <>
      <input
        type="checkbox"
        className={inputClassName}
        id={name}
        onClick={onClick}
        name={name}
        readOnly={readOnly}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      <label className={labelClassName} htmlFor={name}>
        {label}
      </label>
    </>
  );
};

checkBoxWithLabel.propTypes = {
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(checkBoxWithLabel);
