import React from "react";
import PropTypes from "prop-types";

function EdlButton({ type, name, btnClassName, onClick, disabled }) {
  return (
    <>
      <button
        type={type === "button" ? "button" : "submit"}
        className={`button ${btnClassName}`}
        onClick={onClick}
        disabled={disabled}
      >
        {name}
      </button>
    </>
  );
}

EdlButton.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default EdlButton;
