import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes";
import "./libcss";
// import "../node_modules/egi-ui-component/css/main.css";
import "egi-ui-component/css/main.css";

const App = () => {
  return (
    <Provider store={store()}>
      <Routes />
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
