import { all, takeEvery, put, call } from "redux-saga/effects";

import {
  createRegisterationDetailsFailure,
  createRegisterationDetailsSuccess,
} from "../../action/Car/registeration-details";

import { CREATE_REGISTERATION_DETAILS } from "../../action/Car/types/types";

import INSUQUOTE from "../../../utils/insuquoteaxios";

function* createRegisterationDetails(action) {
  try {
    const res = yield INSUQUOTE.post(
      "pub/car/vehicleregestration",
      action.payload
    );
    console.log("payload", res.data.data);
    yield put(createRegisterationDetailsSuccess(action.payload));
    yield call(action.payload.callback);
  } catch (error) {
    yield put(createRegisterationDetailsFailure(action.payload));
  }
}

function* watchCreateRegisterationDetails() {
  yield takeEvery(CREATE_REGISTERATION_DETAILS, createRegisterationDetails);
}

export default function* rootSaga() {
  yield all([watchCreateRegisterationDetails()]);
}
