import {
  POST_DISEASE_HEALTH,
  POST_DISEASE_SUCCESS_HEALTH,
  POST_DISEASE_FAILURE_HEALTH,
} from "./types/types";

export const createPostDisease = (payload) => ({
  type: POST_DISEASE_HEALTH,
  payload,
});

export const createPostDiseaseSuccess = (payload) => ({
  type: POST_DISEASE_SUCCESS_HEALTH,
  payload,
});

export const createPostDiseaseFailure = (payload) => ({
  type: POST_DISEASE_FAILURE_HEALTH,
  payload,
});
