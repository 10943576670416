import { all, takeEvery, put, call } from "redux-saga/effects";

import { CREATE_PROPOSAL_HEALTH } from "../../action/Health/types/types";
import {
  createProposalSuccessHealth,
  createProposalFailureHealth,
} from "../../action/Health/proposal-details";
import { getQuoteid, setProposalID } from "../../../utils/helper";
import API from "../../../utils/axios";

function* createProposalHealthRequest(action) {
  try {
    const quoteID = getQuoteid();

    const res = yield API.post(
      `api/health/quote/${quoteID}/proposal/`,
      action.payload.form
    );

    setProposalID(res.data.data["Proposal id"]);
    yield put(createProposalSuccessHealth(res.data.data));
    yield call(action.payload.callback);
  } catch (error) {
    yield put(createProposalFailureHealth(error));
  }
}

function* watchCreateQuoteHealth() {
  yield takeEvery(CREATE_PROPOSAL_HEALTH, createProposalHealthRequest);
}
export default function* rootSaga() {
  yield all([watchCreateQuoteHealth()]);
}
