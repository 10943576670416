import {
  CREATE_CONTACT_DETAILS,
  CREATE_CONTACT_DETAILS_FAILURE,
  CREATE_CONTACT_DETAILS_SUCCESS,
} from "../types";

export const createContactDetails = (payload) => ({
  type: CREATE_CONTACT_DETAILS,
  payload,
});

export const createContactDetailsSuccess = (payload) => ({
  type: CREATE_CONTACT_DETAILS_SUCCESS,
  payload,
});

export const createContactDetailsFailure = (payload) => ({
  type: CREATE_CONTACT_DETAILS_FAILURE,
  payload,
});
