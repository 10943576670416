import {
  CREATE_PROPOSAL,
  CREATE_PROPOSAL_SUCCESS,
  CREATE_PROPOSAL__FAILURE,
  GET_QUOTE_DETAIL,
  GET_QUOTE_DETAIL_SUCCESS,
  GET_QUOTE_DETAIL_FAILURE,
} from "../../action/types";

const INIT_STATE = {
  proposal_details: null,
  quote_details: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_PROPOSAL:
      return { ...state, proposal_details: action.payload };
    case CREATE_PROPOSAL_SUCCESS:
      return { ...state, proposal_details: action.payload };
    case CREATE_PROPOSAL__FAILURE:
      return { ...state, proposal_details: action.payload };
    case GET_QUOTE_DETAIL:
      return { ...state, quote_details: action.payload };
    case GET_QUOTE_DETAIL_SUCCESS:
      return { ...state, quote_details: action.payload };
    case GET_QUOTE_DETAIL_FAILURE:
      return { ...state, quote_details: action.payload };
    default:
      return state;
  }
};
