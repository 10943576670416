import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const EdlInputWithLabel = ({
  label,
  changeValue,
  type,
  maxLength,
  disabled,
  value,
  multiline,
  rows,
  blurChange,
}) => {
  const textFieldRef = React.useRef(null);

  React.useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    textFieldRef?.current?.addEventListener("wheel", handleWheel);

    return () => {
      textFieldRef?.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div className="custome-input">
      <TextField
        id={label}
        label={label}
        multiline={multiline}
        onChange={changeValue}
        type={type}
        rows={rows}
        inputProps={{ maxLength, inputMode: "numeric", pattern: "[0-9]*" }}
        disabled={disabled}
        value={value}
        onBlur={blurChange}
        ref={textFieldRef}
      />
    </div>
  );
};

EdlInputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  changeValue: PropTypes.func,
  blurChange: PropTypes.func,
  type: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

export default EdlInputWithLabel;
