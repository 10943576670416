import React from "react";
import PropTypes from "prop-types";

const Modal = ({
  isModalOpen,
  children,
  isLargeModal,
  pedpopup,
  addonspopup,
}) => {
  return (
    <div
      className={`modal ${
        isModalOpen ? "show d-block animated fadeIn" : "d-none"
      }`}
    >
      <div
        className={`modal-dialog modal-dialog-centered ${
          isLargeModal ? "max-width-100" : ""
        }`}
      >
        <div
          className={`modal-content ${pedpopup ? "pedmodal" : ""} ${
            addonspopup ? "addonspopup" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  children: PropTypes.node,
  isLargeModal: PropTypes.bool,
  pedpopup: PropTypes.bool,
  addonspopup: PropTypes.bool,
};

export default Modal;
