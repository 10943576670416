import { combineReducers } from "redux";

import MemberDetails from "./Arogya-sanjivani/memberDetails";
import SelectedPlan from "./Arogya-sanjivani/selectedPlan";
import ContactDetails from "./Arogya-sanjivani/contactDetails";
import quickSummary from "./Arogya-sanjivani/quickSummary";
import quickQuote from "./Health/quickQuote";
import HealthquickSummary from "./Health/quick-summary";
import proposalDetails from "./Health/proposal-details";
import lastQuestion from "./Health/last-question";
import addVehicleDetails from "./Car/add-vehicle-details";
import registerationDetails from "./Car/registeration-details";

const appReducer = combineReducers({
  MemberDetails,
  SelectedPlan,
  ContactDetails,
  quickSummary,
  quickQuote,
  HealthquickSummary,
  proposalDetails,
  lastQuestion,
  addVehicleDetails,
  registerationDetails,
});

const reducers = (state, action) => {
  return appReducer(state, action);
};

export default reducers;
