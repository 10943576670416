/* eslint-disable prettier/prettier */
import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

import { capitalize } from "../../utils/helper";

const SelectGender = ({
  id,
  label,
  changeValue,
  values,
  required,
  val,
  disabled,
  variant,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="custome-select">
      {required ? (
        <FormControl disabled={disabled} required>
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            labelId={id}
            style={{ minWidth: 120 }}
            id={id}
            onChange={changeValue}
            value={val}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            // dropDownMenuProps={dropdownMenuProps}
            variant={variant}
          >
            {values?.map((itm) => {
              return <MenuItem value={itm}>{capitalize(itm)}</MenuItem>;
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl disabled={disabled}>
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            labelId={id}
            style={{ minWidth: 120 }}
            id={id}
            onChange={changeValue}
            value={val}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            variant={variant}
          >
            {values?.map((itm) => {
              return <MenuItem value={itm}>{capitalize(itm)}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

SelectGender.propTypes = {
  label: PropTypes.string,
  changeValue: PropTypes.func,
  values: PropTypes.instanceOf(Array),
  val: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  variant:PropTypes.string,
};

export default SelectGender;
