import {
  CREATE_QUOTE_HEALTH,
  CREATE_QUOTE_FAILURE_HEALTH,
  CREATE_QUOTE_SUCCESS_HEALTH,
  GET_QUOTE_HEALTH,
  GET_QUOTE_SUCCESS_HEALTH,
  GET_QUOTE_FAILURE_HEALTH,
} from "../../action/Health/types/types";

const INITIAL_STATE = {
  quickquoteDetails: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_QUOTE_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    case CREATE_QUOTE_SUCCESS_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    case CREATE_QUOTE_FAILURE_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    case GET_QUOTE_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    case GET_QUOTE_SUCCESS_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    case GET_QUOTE_FAILURE_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    default:
      return state;
  }
};
