import { all, takeEvery, put, call } from "redux-saga/effects";

import { CREATE_CONTACT_DETAILS } from "../../action/types";
import {
  createContactDetailsFailure,
  createContactDetailsSuccess,
} from "../../action";
import API from "../../../utils/axios";
import { getProposalID } from "../../../utils/helper";

function* createContactDetailsRequest(action) {
  try {
    const proposalId = getProposalID();
    const res = yield API.post(
      `api/arogya-sanjeevani/quote/proposal/${proposalId}/contact-details/`,
      action.payload.form
    );
    yield call(action.payload.callback);
    yield put(createContactDetailsSuccess(res));
  } catch (error) {
    yield put(createContactDetailsFailure(error));
  }
}

function* watchCreateContactDetails() {
  yield takeEvery(CREATE_CONTACT_DETAILS, createContactDetailsRequest);
}

export default function* rootSaga() {
  yield all([watchCreateContactDetails()]);
}
