// ==================================== ACTIONS FOR QUOTE ==================================

// =============== CREATE QUOTE HEALTH ======================

export const CREATE_QUOTE_HEALTH = "CREATE_QUOTE_HEALTH";
export const CREATE_QUOTE_SUCCESS_HEALTH = "CREATE_QUOTE_SUCCESS_HEALTH";
export const CREATE_QUOTE_FAILURE_HEALTH = "CREATE_QUOTE_FAILURE_HEALTH";

// =============== GET QUOTE HEALTH ======================

export const GET_QUOTE_HEALTH = "GET_QUOTE_HEALTH";
export const GET_QUOTE_SUCCESS_HEALTH = "GET_QUOTE_SUCCESS_HEALTH";
export const GET_QUOTE_FAILURE_HEALTH = "GET_QUOTE_FAILURE_HEALTH";

// =============== Get Quick Summary =============================
export const GET_QUICK_SUMMARY_HEALTH = "GET_QUICK_SUMMARY_HEALTH";
export const GET_QUICK_SUMMARY_SUCCESS_HEALTH =
  "GET_QUICK_SUMMARY_SUCCESS_HEALTH";
export const GET_QUICK_SUMMARY_FAILURE_HEALTH =
  "GET_QUICK_SUMMARY_FAILURE_HEALTH";
// =============== post DISEASE HEALTH ======================
export const POST_DISEASE_HEALTH = "POST_DISEASE_HEALTH";
export const POST_DISEASE_SUCCESS_HEALTH = "POST_DISEASE_SUCCESS_HEALTH";
export const POST_DISEASE_FAILURE_HEALTH = "POST_DISEASE_FAILURE_HEALTH";

// ================= ACTIONS FOR PROPOSALS ==================================

// ============CREATE PROPOSAL HEALTH ======================
export const CREATE_PROPOSAL_HEALTH = "CREATE_PROPOSAL_HEALTH";
export const CREATE_PROPOSAL_SUCCESS_HEALTH = "CREATE_PROPOSAL_SUCCESS_HEALTH";
export const CREATE_PROPOSAL_FAILURE_HEALTH = "CREATE_PROPOSAL_FAILURE_HEALTH";
