import React, { memo } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

const MultiSelectComponent = ({
  value,
  handleChange,
  children,
  label,
  name,
  disabled,
}) => {
  return (
    <div>
      <FormControl disabled={disabled}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          id={label}
          name={name}
          multiple
          value={value}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
        >
          {children}
        </Select>
      </FormControl>
    </div>
  );
};

MultiSelectComponent.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(MultiSelectComponent);
