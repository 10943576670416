import { all, takeEvery, put } from "redux-saga/effects";

import { GET_QUICK_SUMMARY_HEALTH } from "../../action/Health/types/types";
import {
  getquickSummarySuccesshealth,
  getquickSummaryFailurehealth,
} from "../../action/Health/quick-summary";
import API from "../../../utils/axios";
import { getQuoteid } from "../../../utils/helper";

function* getQuickSummaryhealthRequest() {
  try {
    const res = yield API.get(`api/health/quote/${getQuoteid()}/summary/`);
    if (res.data.code === 200) {
      yield put(getquickSummarySuccesshealth(res.data.data));
    } else {
      yield put(getquickSummaryFailurehealth(res));
    }
  } catch (e) {
    yield put(getquickSummaryFailurehealth(e));
  }
}

export function* watchGetQuickSummaryAPI() {
  yield takeEvery(GET_QUICK_SUMMARY_HEALTH, getQuickSummaryhealthRequest);
}

export default function* rootSaga() {
  yield all([watchGetQuickSummaryAPI()]);
}
