import CryptoJS from "crypto-js";

function getData(data, others) {
  const iv = CryptoJS.enc.Utf8.parse(others);
  // console.log("key", DecryptedFunction(process.env.REACT_APP_AES_KEY1));
  let decrypted = CryptoJS.AES.decrypt(
    data,
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY1),
    { iv, mode: CryptoJS.mode.CBC }
  );
  decrypted = decrypted.toString(CryptoJS.enc.Utf8);
  const result = JSON.parse(decrypted);
  return result;
}

export default getData;
