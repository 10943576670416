import {
  GET_QUICK_SUMMARY_HEALTH,
  GET_QUICK_SUMMARY_SUCCESS_HEALTH,
  GET_QUICK_SUMMARY_FAILURE_HEALTH,
} from "./types/types";

export const getquickSummaryhealth = () => ({
  type: GET_QUICK_SUMMARY_HEALTH,
});

export const getquickSummarySuccesshealth = (payload) => ({
  type: GET_QUICK_SUMMARY_SUCCESS_HEALTH,
  payload,
});

export const getquickSummaryFailurehealth = (payload) => ({
  type: GET_QUICK_SUMMARY_FAILURE_HEALTH,
  payload,
});
