// import "date-fns";
import PropTypes from "prop-types";
import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const InputTypeDate = ({
  id,
  label,
  changeValue,
  name,
  dob,
  disable,
  futureDate,
  defaultValue,
  variant,
  className,
}) => {
  return (
    <div className="custome-input-date">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id={id}
          format="dd/MM/yyyy"
          placeholder="DD/MM/YYYY"
          label={label}
          defaultValue={defaultValue}
          value={dob}
          className={className}
          inputVariant={variant}
          onChange={changeValue}
          name={name}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          disabled={disable}
          keyboardIcon={<i />}
          disableFuture={futureDate}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

InputTypeDate.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  changeValue: PropTypes.func,
  name: PropTypes.string,
  dob: PropTypes.string,
  defaultValue: PropTypes.string,
  disable: PropTypes.bool,
  futureDate: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default InputTypeDate;
