// ================== Create Quote =================

export const CREATE_QUOTE = "CREATE_QUOTE";
export const CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS";
export const CREATE_QUOTE_FAILURE = "CREATE_QUOTE_FAILURE";

// =================== Create Proposal ===================

export const CREATE_PROPOSAL = "CREATE_PROPOSAL";
export const CREATE_PROPOSAL_SUCCESS = "CREATE_PROPOSAL_SUCCESS";
export const CREATE_PROPOSAL__FAILURE = "CREATE_PROPOSAL_FAILURE";

// ================= Add Contact Details to Proposal ============
export const CREATE_CONTACT_DETAILS = "CREATE_CONTACT_DETAILS";
export const CREATE_CONTACT_DETAILS_FAILURE = "CREATE_CONTACT_DETAILS_FAILURE";
export const CREATE_CONTACT_DETAILS_SUCCESS = "CREATE_CONTACT_DETAILS_SUCCESS";

// =============== Get Quote =============================
export const GET_QUOTE = "GET_QUOTE";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";
export const GET_QUOTE_FAILURE = "GET_QUOTE_FAILURE";

// ================= Get Quote Details ============
export const GET_QUOTE_DETAIL = "GET_QUOTE_DETAIL";
export const GET_QUOTE_DETAIL_FAILURE = "GET_QUOTE_DETAIL_FAILURE";
export const GET_QUOTE_DETAIL_SUCCESS = "GET_QUOTE_DETAIL_SUCCESS";

//= ==================== Update Quote =========================
export const UPDATE_QUOTE = "UPDATE_QUOTE";
export const UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS";
export const UPDATE_QUOTE_FAILURE = "UPDATE_QUOTE_FAILURE";

// =============== Get Quick Summary =============================
export const GET_QUICK_SUMMARY = "GET_QUICK_SUMMARY";
export const GET_QUICK_SUMMARY_SUCCESS = "GET_QUICK_SUMMARY_SUCCESS";
export const GET_QUICK_SUMMARY_FAILURE = "GET_QUICK_SUMMARY_FAILURE";
