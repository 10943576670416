import React from "react";
import PropTypes from "prop-types";

const EdlCheckBoxWithLabel = ({
  inputClassName,
  labelClassName,
  label,
  checked,
  onChange,
  name,
  value,
}) => {
  return (
    <>
      <input
        type="checkbox"
        className={inputClassName}
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <label className={labelClassName} htmlFor={name}>
        {label}
      </label>
    </>
  );
};

EdlCheckBoxWithLabel.propTypes = {
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default EdlCheckBoxWithLabel;
