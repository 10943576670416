import { all } from "redux-saga/effects";

import MemberDetails from "./Arogya-sanjivani/memberDetails";
import SelectedPlan from "./Arogya-sanjivani/selectedPlan";
import ContactDetails from "./Arogya-sanjivani/contactDetails";
import quickSummary from "./Arogya-sanjivani/quickSummary";
import healthQuickSummary from "./Health/quick-summary";
import quickQuote from "./Health/quickQuote";
import proposalDetails from "./Health/proposal-details";
import lastQuestion from "./Health/last-question";
import registerationDetails from "./Car/registeration-details";

export default function* rootSaga() {
  yield all([
    MemberDetails(),
    SelectedPlan(),
    ContactDetails(),
    quickSummary(),
    quickQuote(),
    healthQuickSummary(),
    proposalDetails(),
    lastQuestion(),
    registerationDetails(),
  ]);
}
