import {
  GET_QUICK_SUMMARY_HEALTH,
  GET_QUICK_SUMMARY_SUCCESS_HEALTH,
  GET_QUICK_SUMMARY_FAILURE_HEALTH,
} from "../../action/Health/types/types";

const INIT_STATE = {
  loading: false,
  quickHealthSummaryDetails: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUICK_SUMMARY_HEALTH:
      return { ...state, loading: true };
    case GET_QUICK_SUMMARY_SUCCESS_HEALTH:
      return {
        ...state,
        loading: false,
        quickHealthSummaryDetails: action.payload,
      };
    case GET_QUICK_SUMMARY_FAILURE_HEALTH:
      return {
        ...state,
        loading: false,
        quickHealthSummaryDetails: action.payload,
      };
    default:
      return state;
  }
};
