import { all, takeEvery, put, call } from "redux-saga/effects";

import { CREATE_QUOTE, GET_QUOTE, UPDATE_QUOTE } from "../../action/types";
import {
  // createQuoteSuccess,
  createQuoteFailure,
  getQuoteSuccess,
  getQuoteFailure,
  updateQuoteSuccess,
  updateQuoteFailure,
  getQuote,
} from "../../action";
import API from "../../../utils/axios";
import LocalStorageStore from "../../../utils/helperstorage";

function* createQuoteRequest(action) {
  // console.log("REDUCER CREATE QUOTE REQUEST ", action);
  try {
    let payload = {
      policy_type: action.payload.selectedPlan,
      si: action.payload.sumInsured ? action.payload.sumInsured : "100000",
    };
    if (action.payload.selectedPlan === "floater") {
      payload = {
        ...payload,
        members: action.payload.membersArray.map(
          ({ isChecked, si, canDeleted, ...rest }) => ({
            ...rest,
          })
        ),
      };
    } else {
      payload = {
        ...payload,
        members: action.payload.membersArray.map(({ isChecked, ...rest }) => ({
          ...rest,
        })),
      };
    }
    const localStoreData = new LocalStorageStore("ags");
    const res = yield API.post("api/arogya-sanjeevani/quote/", payload);
    if (res.data.code === 200) {
      console.log(res.data.data.quote_id);
      yield localStoreData.create("quote_id", res.data.data.quote_id);
      console.log(localStoreData);
      // yield put(createQuoteSuccess(res));
      yield call(action.payload.callback);
    } else {
      yield localStoreData.create("quote_create_error", res);
      yield put(createQuoteFailure(res));
    }
  } catch (e) {
    const localStoreData = new LocalStorageStore("ags");
    if (e?.response?.data?.data?.posp) {
      yield localStoreData.create(
        "quote_create_error",
        e.response.data.data.posp
      );
    }
    yield put(createQuoteFailure(e));
  }
}

function* getQuoteRequest() {
  try {
    const localStoreData = new LocalStorageStore("ags");
    const res = yield API.get(
      `api/arogya-sanjeevani/quote/${localStoreData.getLocalData("quote_id")}/`
    );
    if (res.data.code === 200) {
      yield put(getQuoteSuccess(res.data.data));
    } else {
      yield put(getQuoteFailure(res));
    }
  } catch (e) {
    yield put(getQuoteFailure(e));
  }
}

function* updateQuoteRequest(action) {
  try {
    let payload = {
      policy_type: action.payload.selectedPlan,
      si: action.payload.sumInsured ? action.payload.sumInsured : "100000",
    };
    if (action.payload.selectedPlan === "floater") {
      payload = {
        ...payload,
        members: action.payload.membersArray.map(
          ({ isChecked, si, ...rest }) => ({
            ...rest,
          })
        ),
      };
    } else {
      payload = {
        ...payload,
        members: action.payload.membersArray.map(({ isChecked, ...rest }) => ({
          ...rest,
        })),
      };
    }
    const localStoreData = new LocalStorageStore("ags");
    const res = yield API.put(
      `api/arogya-sanjeevani/quote/${localStoreData.getLocalData("quote_id")}/`,
      payload
    );
    if (res.data.code === 200) {
      yield put(updateQuoteSuccess(res));
      yield put(getQuote());
      yield call(action.payload.callback);
    } else {
      yield put(updateQuoteFailure(res));
    }
  } catch (e) {
    yield put(updateQuoteFailure(e));
  }
}

export function* watchCreateQuoteAPI() {
  yield takeEvery(CREATE_QUOTE, createQuoteRequest);
}

export function* watchGetQuoteAPI() {
  yield takeEvery(GET_QUOTE, getQuoteRequest);
}

export function* watchUpdateQuoteAPI() {
  yield takeEvery(UPDATE_QUOTE, updateQuoteRequest);
}

export default function* rootSaga() {
  yield all([watchCreateQuoteAPI(), watchGetQuoteAPI(), watchUpdateQuoteAPI()]);
}
