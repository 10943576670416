import { all, takeEvery, put, call } from "redux-saga/effects";

import {
  createQuoteSuccessHealth,
  createQuoteFailureHealth,
  getQuoteFailureHealth,
  getQuoteSuccessHealth,
} from "../../action/Health/quickQuote";
import {
  CREATE_QUOTE_HEALTH,
  GET_QUOTE_HEALTH,
} from "../../action/Health/types/types";
import { setQuoteid, getQuoteid } from "../../../utils/helper";
import API from "../../../utils/axios";

function* createQuoteHealthRequest(action) {
  try {
    const res = yield API.post("api/health/quote/", action.payload);
    console.log("payload ", res.data.data.quote);
    yield setQuoteid(res.data.data.quote);

    yield put(createQuoteSuccessHealth(action.payload));
    yield call(action.payload.callback);
  } catch (error) {
    yield put(createQuoteFailureHealth(action.payload));
  }
}
function* getQuoteHealthRequest(action) {
  try {
    console.log(getQuoteid());
    const res = yield API.get(
      `api/health/quote/${getQuoteid()}/`,
      action.payload
    );

    console.log("payload ", res.data.data.quote);
    yield put(getQuoteSuccessHealth(res.data.data.data));
  } catch (error) {
    yield put(getQuoteFailureHealth(error));
  }
}

function* watchGetQuoteHealth() {
  yield takeEvery(GET_QUOTE_HEALTH, getQuoteHealthRequest);
}

function* watchCreateQuoteHealth() {
  yield takeEvery(CREATE_QUOTE_HEALTH, createQuoteHealthRequest);
}

export default function* rootSaga() {
  yield all([watchCreateQuoteHealth(), watchGetQuoteHealth()]);
}
