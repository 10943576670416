import {
  CREATE_PROPOSAL,
  CREATE_PROPOSAL_SUCCESS,
  CREATE_PROPOSAL__FAILURE,
  GET_QUOTE_DETAIL,
  GET_QUOTE_DETAIL_SUCCESS,
  GET_QUOTE_DETAIL_FAILURE,
} from "../types";

export const createProposal = (payload) => ({
  type: CREATE_PROPOSAL,
  payload,
});

export const createProposalSuccess = (payload) => ({
  type: CREATE_PROPOSAL_SUCCESS,
  payload,
});

export const createProposalFailure = (payload) => ({
  type: CREATE_PROPOSAL__FAILURE,
  payload,
});

export const getQuoteDetail = (payload) => ({
  type: GET_QUOTE_DETAIL,
  payload,
});

export const getQuoteDetailSuccess = (payload) => ({
  type: GET_QUOTE_DETAIL_SUCCESS,
  payload,
});

export const getQuoteDetailFailure = (payload) => ({
  type: GET_QUOTE_DETAIL_FAILURE,
  payload,
});
