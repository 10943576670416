import {
  CREATE_PROPOSAL_HEALTH,
  CREATE_PROPOSAL_SUCCESS_HEALTH,
  CREATE_PROPOSAL_FAILURE_HEALTH,
} from "../../action/Health/types/types";

const INITIAL_STATE = {
  proposalDetails: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_PROPOSAL_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    case CREATE_PROPOSAL_SUCCESS_HEALTH:
      return { ...state, quickquoteDetails: action.payload };
    case CREATE_PROPOSAL_FAILURE_HEALTH:
      return { ...state, quickquoteDetails: action.payload };

    default:
      return state;
  }
};
