import React, { memo } from "react";
import PropTypes from "prop-types";

import { currancyFormatter, capitalize } from "../../utils/helper";
import { FLOATER } from "../../utils/constants";

const EdlPlanDiv = ({
  planName,
  sumInsured,
  policyPeriod,
  Premium,
  policyType,
  onClickTooltip,
  isTooltip,
  deductible,
  isDeductible,
  isSummaryPage,
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="goldplantopbox plan-top-box-mediaquery">
            <div className="row">
              {isDeductible ? (
                <>
                  {isSummaryPage ? (
                    <div className="col-md-6 col-lg-4 goldplantitle">
                      {capitalize(policyType)}
                      <span>{capitalize(planName)}</span>
                      <p>Zuno health top up insurance</p>
                    </div>
                  ) : (
                    <div className="col-md-6 col-lg-4 goldplantitle">
                      {capitalize(policyType)}
                      <span>{capitalize(planName)}</span>
                      <p>Selected plan</p>
                    </div>
                  )}
                  <div className="mt-2 mt-md-0 col-md-6 col-lg-2 text-left text-md-right goldpricelistsmallsize">
                    <span>
                      {/* <span>₹</span> */}
                      <span>{sumInsured}</span>
                    </span>
                    {policyType === "individual" ? (
                      <p style={{ width: "230px" }}>
                        Total sum insured of all individual plans
                      </p>
                    ) : (
                      <p>Sum insured</p>
                    )}
                  </div>
                  {policyType === FLOATER && isDeductible && (
                    <div className="mt-2 mt-md-0 col-md-6 text-left text-lg-right col-lg-2 goldpricelistsmallsize">
                      <span>
                        {/* <span>₹</span> */}
                        <span>{deductible}</span>
                      </span>

                      <p>Deductible</p>
                    </div>
                  )}
                  <div className="mt-2 mt-md-0 col-md-6 col-lg-2 text-left text-md-right goldpricelistsmallsize">
                    <span>
                      {policyPeriod}
                      &nbsp;Year
                    </span>
                    <p>Tenure</p>
                  </div>
                  <div className="mt-2 mt-md-0 col-md-6 text-left text-lg-right col-lg-2 goldpricelistsmallsize">
                    <span className="mr-3">
                      <span>₹&nbsp;</span>
                      <span>{currancyFormatter(Premium)}</span>
                    </span>
                    <p>
                      Premium (Incl. of GST)
                      {isTooltip && (
                        <div
                          onClick={onClickTooltip}
                          style={{ display: "contents" }}
                          role="presentation"
                        >
                          <i className="fa fa-info-circle" />
                        </div>
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  {isSummaryPage ? (
                    <div className="col-lg-4 col-md-6 goldplantitle plan-title">
                      {capitalize(policyType)}
                      <span>{capitalize(planName)}</span>
                      <p>Zuno health top up insurance</p>
                    </div>
                  ) : (
                    <div className="col-lg-4 col-md-6 goldplantitle plan-title">
                      {capitalize(policyType)}
                      <span>{capitalize(planName)}</span>
                      <p>Selected plan</p>
                    </div>
                  )}
                  <div className="col-lg-3 col-md-6 goldpricelist plan-div-suminsured-mediaquery">
                    <span className="mr-3 plan-div-sivalue-mediaquery">
                      {/* <span>₹</span> */}
                      <span>{sumInsured}</span>
                    </span>
                    {policyType === "individual" ? (
                      <p
                        style={{ width: "230px" }}
                        className="plan-div-sitext-mediaquery"
                      >
                        Total sum insured of all individual plans
                      </p>
                    ) : (
                      <p className="justify-content-start justify-content-md-end">
                        Sum insured
                      </p>
                    )}
                  </div>
                  <div className="col-lg-2 col-md-6 goldpricelist plan-div-year-mediaquery">
                    <span>
                      {policyPeriod}
                      &nbsp;Year
                    </span>
                    <p className="plan-tenure-mediaquery">Tenure</p>
                  </div>
                  <div className="col-lg-3 col-md-6 goldpricelist plan-div-premium-mediaquery">
                    <span className="mr-3">
                      <span>₹&nbsp;</span>
                      <span>{currancyFormatter(Premium)}</span>
                    </span>
                    <p className="plan-div-premiumtext-mediaquery">
                      Premium (Incl. of GST)
                      {isTooltip && (
                        <div
                          onClick={onClickTooltip}
                          role="presentation"
                          style={{ display: "contents" }}
                        >
                          <i className="fa fa-info-circle" />
                        </div>
                      )}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EdlPlanDiv.propTypes = {
  planName: PropTypes.string,
  sumInsured: PropTypes.string,
  policyPeriod: PropTypes.number,
  Premium: PropTypes.number,
  policyType: PropTypes.string,
  onClickTooltip: PropTypes.func,
  isTooltip: PropTypes.bool,
  deductible: PropTypes.string,
  isDeductible: PropTypes.bool,
  isSummaryPage: PropTypes.bool,
};

export default memo(EdlPlanDiv);
