import React, { memo } from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const selectTag = ({ label, children, onChange, value, name, disabled }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="custome-select">
      <FormControl disabled={disabled}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          style={{ minWidth: 120 }}
          // variant={"outlined"}
          id={label}
          onChange={onChange}
          value={value}
          name={name}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          {children}
        </Select>
      </FormControl>
    </div>
  );
};

selectTag.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(selectTag);
