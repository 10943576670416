import md5 from "md5";
import {
  MALE,
  FEMALE,
  TRANSGENDER,
  ENCRYPTION_URLS,
  ENCRYPTION_EXEMPT_URLS,
} from "./constants";

export const setToken = (token) => localStorage.setItem("token", token);

export const getToken = () => localStorage.getItem("token");

export const removeToken = () => localStorage.removeItem("token");

export const apiUrl = process.env.REACT_APP_API_ENDPOINT;
export const SALT_STRING = process.env.REACT_APP_SALT_STRING;

export const insuquoteApiUrl = process.env.REACT_APP_INSUQUOTE_API_ENDPOINT;
export const dashboardHomePageUrl = process.env.REACT_APP_DASHBOARD_URL;

export const setQuoteid = (quoteid) => localStorage.setItem("quoteid", quoteid);

export const getQuoteid = () => {
  return localStorage.getItem("quoteid");
};

export const setReqId = (reqid) => localStorage.setItem("reqid", reqid);
export const getReqId = () => {
  return localStorage.getItem("reqid");
};

export const setPolicytype = (policyType) =>
  localStorage.setItem("policyType", policyType);

export const setSI = (sI) => localStorage.setItem("sI", sI);

export const getSI = () => localStorage.getItem("sI");

export const setProposalID = (proposalID) => {
  localStorage.setItem("proposal_id", proposalID);
};

export const getProposalID = () => {
  return localStorage.getItem("proposal_id");
};
export const removeProposalID = () => {
  console.log("removing");
  localStorage.removeItem("proposal_id");
};
export const setSelfSI = (selfSI) => localStorage.setItem("selfSI", selfSI);

export const getSelfSI = () => localStorage.getItem("selfSI");

export const setSpouseSI = (spouseSI) =>
  localStorage.setItem("spouseSI", spouseSI);

export const getSpouseSI = () => localStorage.getItem("spouseSI");

export const setSonSI = (sonSI) => localStorage.setItem("sonSI", sonSI);

export const getSonSI = () => localStorage.getItem("sonSI");

export const numberFormat = (value) => {
  let val = Math.abs(value);
  if (val >= 10000000) {
    val = `${val / 10000000} Cr`;
  } else if (val >= 100000) {
    val = `${val / 100000} Lac`;
  } else if (val < 100000) {
    val = `${val / 1000} Thousand`;
  }
  return val;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeAllCharacters = (s) => {
  if (typeof s !== "string") return "";
  return s.toUpperCase();
};

export const capitalizeAllCharactersWithStandardFormat = (s) => {
  if (typeof s !== "string") return "";
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
};

export const getAge = (birthDate) => {
  const now = new Date();
  const born = new Date(birthDate);
  const birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
  if (now >= birthday) return now.getFullYear() - born.getFullYear();
  return now.getFullYear() - born.getFullYear() - 1;
};

export const monthDiff = (dateFrom, dateTo) => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

export const dayDiff = (dateFrom, dateTo) => {
  const date1 = new Date(dateFrom);
  const date2 = new Date(dateTo);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  //

  return diffDays;
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const setMembersInfo = (arr) => {
  localStorage.setItem("members", arr);
};

export const getMembersInfo = () => localStorage.getItem("members");

export const setMemberDOB = (arr, key) => {
  localStorage.setItem(`${key}_dob`, arr);
};
export const getMemberDOB = (key) => localStorage.getItem(`${key}_dob`);

export const setMemberName = (arr, key) => {
  localStorage.setItem(`${key}_name`, arr);
};
export const getMemberName = (key) => localStorage.getItem(`${key}_name`);

export const currancyFormatter = (number) => {
  return parseFloat(number)
    .toFixed(2)
    .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
    .replace(/\.0+$/, "");
};

export const currancyFormatterFixedToZeroPosition = (number) => {
  return parseFloat(number)
    .toFixed(0)
    .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
    .replace(/\.0+$/, "");
};
export const currancyFormatter2 = (number) => {
  return parseFloat(number).replace(/\d(?=(\d{3})+\.)/g, "$1,");
};

export const formatNames = (names) => {
  const f = names.split(" ");
  for (let i = 0; i < f.length; i += 1) {
    f[i] = f[i].charAt(0).toUpperCase() + f[i].slice(1);
  }

  return f.join(" ");
};

export const validateMobile = (mobile) => {
  const errors = {};
  if (mobile.length < 10) errors.mobile = "Please enter full mobile number";
  // else if (mobile < 1111111111 || mobile > 9999999999)
  //   errors.mobile = "Please Enter a Valid Mobile Number";
  return { errors, isValid: Object.keys(errors).length <= 0 };
};

export const validateOTP = (otp) => {
  const errors = {};
  if (otp.length < 4)
    errors.otp = "Please enter 4 digit code sent to your mobile";
  return { errors, isValid: Object.keys(errors).length <= 0 };
};

export const numberFormatIndianCurrency = (number) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);

export const setMobileNo = (mobileNo) =>
  localStorage.setItem("mobileNo", mobileNo);

export const getMobileNo = () => localStorage.getItem("mobileNo");

export const setAddress1 = (address1) =>
  localStorage.setItem("address1", address1);

export const getAddress1 = () => localStorage.getItem("address1");

export const setRegion = (region) => localStorage.setItem("state", region);

export const getRegion = () => localStorage.getItem("state");

export const setCity = (city) => localStorage.setItem("city", city);

export const getCity = () => localStorage.getItem("city");

export const setPincode = (pincode) => localStorage.setItem("pincode", pincode);

export const getPincode = () => localStorage.getItem("pincode");

export const setEmail = (email) => localStorage.setItem("email", email);

export const getEmail = () => localStorage.getItem("email");

export const setIsPermanantAddress = (isPermanentAddress) =>
  localStorage.setItem("isPermanentAddress", isPermanentAddress);

export const getIsPermanentAddress = () =>
  localStorage.getItem("isPermanentAddress");

export const setPermanantAddress = (permanentAddress) =>
  localStorage.setItem("permanentAddress", permanentAddress);

export const getPermanentAddress = () =>
  localStorage.getItem("permanentAddress");
export const setPermanentCity = (permanentCity) =>
  localStorage.setItem("permanentCity", permanentCity);

export const getPermanentCity = () => localStorage.getItem("permanentCity");

export const setPermanentState = (permanentState) =>
  localStorage.setItem("permanentState", permanentState);

export const getPermanentState = () => localStorage.getItem("permanentState");

export const setPermanentPincode = (permanentPincode) =>
  localStorage.setItem("permanentPincode", permanentPincode);

export const getPermanentPincode = () =>
  localStorage.getItem("permanentPincode");

export const setNomineeName = (nomineeName) =>
  localStorage.setItem("nomineeName", nomineeName);

export const getNomineeName = () => localStorage.getItem("nomineeName");

export const setNomineeDob = (nomineeDob) =>
  localStorage.setItem("nomineeDob", nomineeDob);

export const getNomineeDob = () => localStorage.getItem("nomineeDob");

export const setNomineeRelation = (nomineeRelation) =>
  localStorage.setItem("nomineeRelation", nomineeRelation);

export const getNomineeRelation = () => localStorage.getItem("nomineeRelation");

export const isValidBMI = (height, weight) => {
  const heightMeters = height / 100;
  const BMI = weight / (heightMeters * heightMeters);
  console.log(BMI);
  return BMI;
};

export const splitStrings = (str, value, len) => {
  let a = str.split(value);
  a = a.filter((itm) => itm !== "");
  return a.length < len;
};

export const findOddfromArray = (arr1, arr) => {
  let arr2 = arr;
  let val = "";
  let isOther = false;
  for (let i = 0; i < arr2.length; i += 1) {
    if (!arr1.includes(arr2[i].name)) {
      val = arr2[i].name;
      const discard = arr2[i].name;
      isOther = true;
      arr2 = arr2.filter((itm) => itm.name !== discard);
    }
  }
  console.log(arr2);
  return { val, arr2, isOther };
};
export const calculateDiscount = (discount, base) => {
  const percentage = (discount * 100) / base;
  console.log(percentage);
  return Math.floor(parseFloat(percentage).toFixed(2));
};

export const createHashWithSalt = (input) => {
  const saltedValue = SALT_STRING + input;
  return md5(saltedValue);
};

export const createHashWithSaltOtp = (input, salt) => {
  const saltedValue = salt + input;
  return md5(saltedValue);
};

export const maxLengthCheck = (e) => {
  if (e.target.value.length > e.target.maxLength) {
    e.target.value = e.target.value.slice(0, e.target.maxLength);
  }
};

export const stripAndFormatString = (val, delimiter) => {
  const returnValue = [];
  val.split(delimiter).forEach((itm) => {
    returnValue.push(capitalize(itm));
  });
  return returnValue.join(" ");
};

export const addonsFormatter = (val) => {
  if (val === "copay_premium") {
    return "Base Policy Co-pay Support";
  }
  if (val === "assistance_service") {
    return "Assistance Services(Domestic and Worldwide)";
  }
  if (val === "born_carewith_maternity_premium") {
    return "New Born Care";
  }
  if (val === "new_born_maternity_discount_premium") {
    return "New Born Care";
  }
  if (val === "born_carewith_si_premium") {
    return "New Born Care";
  }
  if (val === "born_care_with_si_premium") {
    return "New Born Care";
  }
  if (val === "critical_illnesses_premium") {
    return "Double Sum Insured for Critical Illness";
  }
  if (val === "dental_opd_premium") {
    return "Dental OPD Cover";
  }
  if (val === "emergency_ambulance_premium") {
    return "Emergency Ambulance";
  }
  if (val === "higher_room_rent_premium") {
    return "Base Policy Higher Room Rent Support";
  }
  if (val === "hospital_cash_premium") {
    return "Hospital Cash";
  }
  if (val === "maternity_discount_premium") {
    return "Maternity";
  }
  if (val === "organ_donor_premium") {
    return "Organ Donor";
  }
  if (val === "recharge_premium") {
    return "Recharge Benefit";
  }
  if (val === "restoration_premium") {
    return "Restoration Benefit";
  }
  if (val === "vaccionation_cover_premium") {
    return "Vaccination Cover";
  }
  if (val === "ped_premium") {
    return "PED Waiting Period Waiver";
  }

  return val;
};

export const formatChildNames = (name) => {
  if (["child1", "child2", "child3"].includes(name)) {
    return "Son/Daughter";
  }
  if (["grandchild/children1", "grandchild/children2"].includes(name)) {
    return "Granddaughter/Grandson";
  }
  if (["brother1", "brother2"].includes(name)) {
    return "Brother";
  }
  if (["sister1", "sister2"].includes(name)) {
    return "Sister";
  }
  if (["niece1", "niece2"].includes(name)) {
    return "Niece";
  }
  if (["nephew1", "nephew2"].includes(name)) {
    return "Nephew";
  }
  return name;
};

export const formatRelationReplace = (ele) => {
  const element = ele;

  let point = element.indexOf("brother1");
  if (point !== -1) {
    element[point] = "Brother";
  }
  point = element.indexOf("brother2");

  if (point !== -1) {
    element[point] = "Brother";
  }

  point = element.indexOf("sister1");

  if (point !== -1) {
    element[point] = "Sister";
  }
  point = element.indexOf("sister2");

  if (point !== -1) {
    element[point] = "Sister";
  }

  return element;
};

export const AntiformatRelationReplace = (ele) => {
  const element = ele;

  if (ele === "Brother") {
    return "brother1";
  }

  if (ele === "Sister") {
    return "sister1";
  }
  return element;
};

export const AntiformatChildNames = (name) => {
  if (["Granddaughter/Grandson"].includes(name)) {
    return "grandchild/children";
  }

  return name;
};
export function parseHTML(parseableHTML) {
  const parser = new DOMParser();
  const htmlText = parser.parseFromString(parseableHTML, "text/html").all[0]
    .textContent;
  return htmlText;
}

export function getGender(gender, relation) {
  if (relation !== "grandchild/children" && gender.toLowerCase() === MALE) {
    return "Son";
  }
  if (relation !== "grandchild/children" && gender.toLowerCase() === FEMALE) {
    return "Daughter";
  }
  if (relation === "grandchild/children" && gender.toLowerCase() === MALE) {
    return "Grandson";
  }
  if (relation === "grandchild/children" && gender.toLowerCase() === FEMALE) {
    return "Granddaughter";
  }

  if (
    relation !== "grandchild/children" &&
    gender.toLowerCase() === TRANSGENDER
  ) {
    return "Son";
  }
  return true;
}

export const PROPOSER_INSURED_RELATION = "Self";

export const PAYMENT_METHOD_ENV = process.env.REACT_APP_GO_LIVE_PAYMENT;

export function goBackFunction(pageName, history) {
  switch (pageName) {
    case "policy-details":
      history.push("/topup/quick-quote");
      break;

    case "selected-plan":
      history.push("/topup/policy-details");
      break;

    case "quick-summary":
      history.push("/topup/selected-plan");
      break;

    case "proposal-details":
      history.push("/topup/quick-summary");
      break;

    case "nominee-details":
      history.push("/topup/proposal-details");
      break;

    case "address-details":
      history.push("/topup/nominee-details");
      break;

    case "discounts":
      history.push("/topup/address-details");
      break;

    case "insured-data":
      history.push("/topup/discounts");
      break;

    default:
      history.go(-1);
      break;
  }
}

export const getAbhaApiPayload = (abhaDetails) => {
  const abhaDetailsForApi = [];
  for (let i = 0; i < abhaDetails.length; i += 1) {
    abhaDetailsForApi[i] = {};
    abhaDetailsForApi[i].insured_id = String(abhaDetails[i]?.insured_id);
    abhaDetailsForApi[i].abha_id = abhaDetails[i]?.isVerified
      ? abhaDetails[i]?.abha_id
      : null;
  }
  return { members: abhaDetailsForApi };
};

export const isSubstringPresent = (arr, string) => {
  return arr.filter((subString) => string.includes(subString)).length > 0;
};

export const checkValidEncryptionApi = (url) => {
  if (
    isSubstringPresent(ENCRYPTION_URLS, url) &&
    !isSubstringPresent(ENCRYPTION_EXEMPT_URLS, url)
  ) {
    return true;
  }
  return false;
};

export const removeSessionStorageKeysWithPrefix = (prefix) => {
  for (let i = sessionStorage.length - 1; i >= 0; i -= 1) {
    const key = sessionStorage.key(i);
    if (key.startsWith(prefix)) {
      sessionStorage.removeItem(key);
    }
  }
};
