import { lazy } from "react";
import premiumNotFound from "../container/Arogya-sanjivani/premiumnotfound";

const MemberDetails = lazy(() =>
  import("../container/Arogya-sanjivani/memberDetails")
);
const PreExistingDisease = lazy(() =>
  import("../container/Arogya-sanjivani/lastQuestion")
);
const ChoosePlan = lazy(() =>
  import("../container/Arogya-sanjivani/choosePlan")
);
const SelectedPlan = lazy(() =>
  import("../container/Arogya-sanjivani/selectedPLan")
);
const NomineeDetailsAGS = lazy(() =>
  import("../container/Arogya-sanjivani/nomineeDetails")
);
const ContactDetails = lazy(() =>
  import("../container/Arogya-sanjivani/contactDetails")
);
const InsuredDetails = lazy(() =>
  import("../container/Arogya-sanjivani/insuredData")
);
const QuickSummary = lazy(() =>
  import("../container/Arogya-sanjivani/quickSummary")
);
const ChoosePlanQuickSummary = lazy(() =>
  import("../container/Arogya-sanjivani/chooseplanSummary")
);

const PaynowPayment = lazy(() =>
  import("../container/Arogya-sanjivani/paynowPayment")
);
const GenerateOTP = lazy(() =>
  import("../container/Arogya-sanjivani/generateOTP")
);
const VerifyOTP = lazy(() => import("../container/Arogya-sanjivani/verifyOTP"));
const ThankYou = lazy(() => import("../container/Arogya-sanjivani/thankYou"));
const PaymentFailure = lazy(() =>
  import("../container/Arogya-sanjivani/paymentfailure")
);
const PaymentFailureUser = lazy(() =>
  import("../container/Arogya-sanjivani/paymentfailureuser")
);
const Nstp = lazy(() => import("../container/Arogya-sanjivani/nstpcase"));
const Downloadpolicy = lazy(() =>
  import("../container/Arogya-sanjivani/downloadpolicy")
);
const Customercare = lazy(() =>
  import("../container/Arogya-sanjivani/customercare")
);
const ThankYouConsent = lazy(() =>
  import("../container/Arogya-sanjivani/thankYouConsent")
);
const NotFound = lazy(() => import("../container/Arogya-sanjivani/notFound"));
const Error404 = lazy(() => import("../container/Arogya-sanjivani/404"));
const TopupError404 = lazy(() => import("../container/Topup/404"));
const MadeOurday = lazy(() =>
  import("../container/Arogya-sanjivani/madeOurday")
);
const MadeOurdayDetails = lazy(() =>
  import("../container/Arogya-sanjivani/madeourdayDetails")
);
const TopupPaymentFailure = lazy(() =>
  import("../container/Topup/topup-failure")
);
const thankyouUserside = lazy(() =>
  import("../container/Arogya-sanjivani/thankyouUser")
);
const TermsCondition = lazy(() => import("../container/termsCondition"));
const QuickQuote = lazy(() => import("../container/Health/quick-quote"));
const PolicyDetails = lazy(() => import("../container/Health/policy-details"));
const QuickSummaryplan = lazy(() =>
  import("../container/Health/quick-summary")
);
const LastQuestion = lazy(() => import("../container/Health/last-question"));
const MedicalCondition = lazy(() =>
  import("../container/Health/medical-condition-details")
);

const ProposalDetails = lazy(() =>
  import("../container/Health/proposal-details")
);
const PaymentReceived = lazy(() =>
  import("../container/Health/payment-received")
);
const Congratulation = lazy(() => import("../container/Health/congratulation"));

const Inclusions = lazy(() => import("../container/Health/inclusions"));

const NomineeDetails = lazy(() =>
  import("../container/Health/nominee-details")
);

const AddressDetails = lazy(() =>
  import("../container/Health/address-details")
);

const TopupDiscounts = lazy(() => import("../container/Topup/topup-discount"));
const PaymentPage = lazy(() => import("../container/Topup/paymentPage"));
const TopupQuickSummaryplan = lazy(() =>
  import("../container/Topup/topupquick-summary")
);

const TopupQuickQuote = lazy(() =>
  import("../container/Topup/topupQuickquote")
);
const TopUpLastQuestion = lazy(() =>
  import("../container/Topup/topup-lastquestion")
);
const TopupCongratulation = lazy(() =>
  import("../container/Topup/topupcongratulation")
);
const TopupPaynowPayment = lazy(() =>
  import("../container/Topup/pay-now-payment")
);
const TopupPaymentReceived = lazy(() =>
  import("../container/Topup/topuppayment-received")
);
const TopupPlancomparison = lazy(() =>
  import("../container/Topup/topupplan-comparison")
);
const TopupPolicyDetails = lazy(() =>
  import("../container/Topup/topuppolicy-details")
);
const TopupTerms = lazy(() =>
  import("../container/Topup/topup-terms-condition")
);
const TopUpSelectedPlan = lazy(() =>
  import("../container/Topup/topup-selectedplan")
);
const TopupUserPaymentFailure = lazy(() =>
  import("../container/Topup/topup-customer-failure")
);
const DashBoardComponent = lazy(() =>
  import("../components/DashBoardComponent/DashBoardComponent")
);
const TopupProposalDetails = lazy(() =>
  import("../container/Topup/topup-proposal")
);
const TopupNomineeDetails = lazy(() =>
  import("../container/Topup/topup-nominee-details")
);
const TopupAddressDetails = lazy(() =>
  import("../container/Topup/topup-address-details")
);
const TopupProposalSummary = lazy(() =>
  import("../container/Topup/topup-proposaldetails")
);
const TopupThankyouUser = lazy(() =>
  import("../container/Topup/topup-thankyou-user")
);
const TopupNstp = lazy(() => import("../container/Topup/topup-nstp"));
const TopupPortDecline = lazy(() =>
  import("../container/Topup/topup-portability-decline")
);
const TopupThankyou = lazy(() => import("../container/Topup/topup-thankyou"));
const TopupMadeouDay = lazy(() =>
  import("../container/Topup/topup-madeourday")
);
const TopupPaymentProgress = lazy(() =>
  import("../container/Topup/topup-payment-progress")
);
const TopupPaymentPaylater = lazy(() =>
  import("../container/Topup/topup-paylater-submitted")
);

const TopupRenewalDetails = lazy(() =>
  import("../container/Topup/topup-renewal-details")
);
const TopupRenewalEditInsurerDetails = lazy(() =>
  import("../container/Topup/topup-renewal-edit-insurer")
);
const TopupChoices = lazy(() => import("../container/Topup/topupChoices"));
const VehicleDetails = lazy(() => import("../container/Car/vehicle-details"));
const AddVehicleDetails = lazy(() =>
  import("../container/Car/add-vehicle-details")
);
const CarProposalDetails = lazy(() =>
  import("../container/Car/proposal-details")
);
const CarCustomerSummary = lazy(() =>
  import("../container/Car/car-customer-summary")
);
const CarInspectionPage = lazy(() => import("../container/Car/car-inspection"));
const CarPaymentPage = lazy(() => import("../container/Car/car-payment-pages"));
const CarUserPaymentFailure = lazy(() =>
  import("../container/Car/user-payment-failure")
);
const CarThankYOuUser = lazy(() =>
  import("../container/Car/thank-you-customer")
);
const CarPaymentProgress = lazy(() =>
  import("../container/Car/payment-progress-page")
);
const CarPaymentFailure = lazy(() =>
  import("../container/Car/car-payment-failure")
);
const CarMadeOurday = lazy(() =>
  import("../container/Car/car-madeour-details")
);
const OwnerDetails = lazy(() => import("../container/Car/owner-details"));
const Thankyou = lazy(() => import("../container/Car/thank-you"));
const RegistrationDetails = lazy(() =>
  import("../container/Car/registration-details")
);

// NSTP PAGES

const NstpBookAppointment = lazy(() =>
  import("../container/Nstp/nstpBookAppointment")
);
const nstpCustomerThankYou = lazy(() =>
  import("../container/Nstp/nstpCustomerThankYou")
);

const routes = [
  {
    path: "/",
    exact: true,
    name: "Dash Board",
    component: DashBoardComponent,
    private: false,
  },

  {
    path: "/arogya-sanjivani",
    exact: true,
    name: "Member Details",
    component: MemberDetails,
    private: false,
  },
  {
    path: "/arogya-sanjivani/ped",
    exact: true,
    name: "Arogya Sanjeevani PED",
    component: PreExistingDisease,
    private: false,
  },
  {
    path: "/arogya-sanjivani/member-details",
    exact: true,
    name: "Member Details",
    component: MemberDetails,
    private: false,
  },
  {
    path: "/arogya-sanjivani/choose-plan",
    exact: true,
    name: "Choose Plan",
    component: ChoosePlan,
    private: false,
  },
  {
    path: "/arogya-sanjivani/selected-plan",
    exact: true,
    name: "Selected Plan",
    component: SelectedPlan,
    private: false,
  },
  {
    path: "/arogya-sanjivani/nominee-details",
    exact: true,
    name: "Nominee Details",
    component: NomineeDetailsAGS,
    private: false,
  },
  {
    path: "/arogya-sanjivani/contact-details",
    exact: true,
    name: "Contact Details",
    component: ContactDetails,
    private: false,
  },
  {
    path: "/arogya-sanjivani/insured-data",
    exact: true,
    name: "Proposal and Contact Details",
    component: InsuredDetails,
    private: false,
  },
  {
    path: "/arogya-sanjivani/quick-summary",
    exact: true,
    name: "Quick Summary",
    component: QuickSummary,
    private: false,
  },
  {
    path: "/arogya-sanjivani/chooseplan-quick-summary",
    exact: true,
    name: "Quick Summary",
    component: ChoosePlanQuickSummary,
    private: false,
  },
  {
    path: "/arogya-sanjivani/paynow-payment",
    exact: true,
    name: "Pay now Payment",
    component: PaynowPayment,
    private: false,
  },
  {
    path: "/arogya-sanjivani/thank-you",
    exact: true,
    name: "Thank you",
    component: ThankYou,
    private: false,
  },
  {
    path: "/arogya-sanjivani/payment-failure",
    exact: true,
    name: "PaymentFailure",
    component: PaymentFailure,
    private: false,
  },
  {
    path: "/arogya-sanjivani/payment-failure-user",
    exact: true,
    name: "PaymentFailureuser",
    component: PaymentFailureUser,
    private: false,
  },
  {
    path: "/arogya-sanjivani/customer-care",
    exact: true,
    name: "Customercare",
    component: Customercare,
    private: false,
  },
  {
    path: "/arogya-sanjivani/nstp",
    exact: true,
    name: "Nstp",
    component: Nstp,
    private: false,
  },

  {
    path: "/arogya-sanjivani/downloadpolicy",
    exact: true,
    name: "Downloadpolicy",
    component: Downloadpolicy,
    private: false,
  },

  {
    path: "/arogya-sanjivani/consent-thanks",
    exact: true,
    name: "Thank you Consent",
    component: ThankYouConsent,
    private: false,
  },
  {
    path: "/arogya-sanjivani/not-found",
    exact: true,
    name: "Not you",
    component: NotFound,
  },
  {
    path: "/arogya-sanjivani/premium-not-found",
    exact: true,
    name: "premium not found",
    component: premiumNotFound,
  },
  {
    path: "/arogya-sanjivani/error",
    exact: true,
    name: "Error",
    component: Error404,
  },
  {
    path: "/arogya-sanjivani/generate-otp",
    exact: true,
    name: "Generate OTP",
    component: GenerateOTP,
    private: false,
  },
  {
    path: "/arogya-sanjivani/verify-otp",
    exact: true,
    name: "Verify OTP",
    component: VerifyOTP,
    private: false,
  },
  {
    path: "/arogya-sanjivani/made-ourday",
    exact: true,
    name: "Made Our Day",
    component: MadeOurday,
    private: false,
  },
  {
    path: "/arogya-sanjivani/made-ourday-details",
    exact: true,
    name: "Made Ourday Details",
    component: MadeOurdayDetails,
    private: false,
  },
  {
    path: "/arogya-sanjivani/thank-you-user",
    exact: true,
    name: "thankyouUsersides",
    component: thankyouUserside,
    private: false,
  },
  {
    path: "/terms-condition",
    exact: true,
    name: "Terms & Condition",
    component: TermsCondition,
    private: false,
  },
  {
    path: "/health/quick-quote",
    exact: true,
    name: "Quick Quote",
    component: QuickQuote,
    private: false,
  },
  {
    path: "/health/policy-details",
    exact: true,
    name: "Policy Details",
    component: PolicyDetails,
    private: false,
  },
  {
    path: "/health/quick-summary",
    exact: true,
    name: "Quick Summary Gold Plan",
    component: QuickSummaryplan,
    private: false,
  },
  {
    path: "/health/last-question",
    exact: true,
    name: "Last Question",
    component: LastQuestion,
    private: false,
  },
  {
    path: "/health/medical-condition-details",
    exact: true,
    name: "Medical Condition Details",
    component: MedicalCondition,
    private: false,
  },
  {
    path: "/health/proposal-details",
    exact: true,
    name: "Proposal Details",
    component: ProposalDetails,
    private: false,
  },
  {
    path: "/health/nominee-details",
    exact: true,
    name: "Nominee Details",
    component: NomineeDetails,
    private: false,
  },
  {
    path: "/health/address-details",
    exact: true,
    name: "Address Details",
    component: AddressDetails,
  },
  {
    path: "/health/payment-received",
    exact: true,
    name: "Payment Received",
    component: PaymentReceived,
    private: false,
  },
  {
    path: "/health/congratulations",
    exact: true,
    name: "Congratulation",
    component: Congratulation,
    private: false,
  },
  {
    path: "/health/inclusions",
    exact: true,
    name: "Inclusions",
    component: Inclusions,
    private: false,
  },
  {
    path: "/topup/quick-quote",
    exact: true,
    name: "Topup Quick Quote",
    component: TopupQuickQuote,
    private: false,
  },
  {
    path: "/topup/quick-summary",
    exact: true,
    name: "Quick Summary Gold Plan",
    component: TopupQuickSummaryplan,
    private: false,
  },
  {
    path: "/topup/insured-data",
    exact: true,
    name: "Proposal and Contact Details",
    component: TopupProposalSummary,
    private: false,
  },
  {
    path: "/topup/congratulations",
    exact: true,
    name: "Congratulation",
    component: TopupCongratulation,
    private: false,
  },
  {
    path: "/topup/choices",
    exact: true,
    name: "Choices",
    component: TopupChoices,
    private: false,
  },
  {
    path: "/topup/payment-received",
    exact: true,
    name: "Payment Received",
    component: TopupPaymentReceived,
    private: false,
  },
  {
    path: "/topup/selected-plan",
    exact: true,
    name: "Selected Plan",
    component: TopUpSelectedPlan,
    private: false,
  },
  {
    path: "/topup/plan-comparison",
    exact: true,
    name: "Plan Comparison",
    component: TopupPlancomparison,
    private: false,
  },
  {
    path: "/topup/policy-details",
    exact: true,
    name: "Policy Details",
    component: TopupPolicyDetails,
    private: false,
  },
  {
    path: "/topup/ped",
    exact: true,
    name: "Last Question",
    component: TopUpLastQuestion,
    private: false,
  },
  {
    path: "/topup/proposal-details",
    exact: true,
    name: "Proposal Details",
    component: TopupProposalDetails,
    private: false,
  },
  {
    path: "/topup/nominee-details",
    exact: true,
    name: "Nominee Details",
    component: TopupNomineeDetails,
    private: false,
  },
  {
    path: "/topup/address-details",
    exact: true,
    name: "Address Details",
    component: TopupAddressDetails,
    private: false,
  },
  {
    path: "/topup/discounts",
    exact: true,
    name: "Dicounts",
    component: TopupDiscounts,
    private: false,
  },
  {
    path: "/topup/payment",
    exact: true,
    name: "Payment Page",
    component: PaymentPage,
    private: false,
  },

  {
    path: "/topup/error",
    exact: true,
    name: "Error Page",
    component: TopupError404,
    private: false,
  },

  {
    path: "/topup/terms",
    exact: true,
    name: "Terms And Condition",
    component: TopupTerms,
    private: false,
  },
  {
    path: "/topup/proposal-summary",
    exact: true,
    name: "Proposal Details",
    component: TopupProposalSummary,
    private: false,
  },

  {
    path: "/topup/made-ourday",
    exact: true,
    name: "Thank You",
    component: TopupMadeouDay,
    private: false,
  },
  {
    path: "/topup/nstp",
    exact: true,
    name: "Topup NSTP",
    component: TopupNstp,
    private: false,
  },
  {
    path: "/topup/paynow-payment",
    exact: true,
    name: "Paynow Payment",
    component: TopupPaynowPayment,
    private: false,
  },
  {
    path: "/topup/thank-you-user",
    exact: true,
    name: "User Thankyou",
    component: TopupThankyouUser,
    private: false,
  },
  {
    path: "/topup/payment-progress",
    exact: true,
    name: "Payment Progress",
    component: TopupPaymentProgress,
    private: false,
  },

  {
    path: "/topup/payment-paylater",
    exact: true,
    name: "Paylater submitted ",
    component: TopupPaymentPaylater,
    private: false,
  },
  {
    path: "/topup/thank-you",
    exact: true,
    name: "Thank you",
    component: TopupThankyou,
    private: false,
  },
  {
    path: "/topup/payment-failure",
    exact: true,
    name: "TopupPaymentFailure",
    component: TopupPaymentFailure,
    private: false,
  },
  {
    path: "/topup/customer-payment-failure",
    exact: true,
    name: "TopupPaymentFailure",
    component: TopupUserPaymentFailure,
    private: false,
  },
  {
    path: "/car/vehicle-details",
    exact: true,
    name: "Vehicle Details",
    component: VehicleDetails,
    private: false,
  },
  {
    path: "/car/inspection",
    exact: true,
    name: "Vehicle Inspection",
    component: CarInspectionPage,
    private: false,
  },
  {
    path: "/car/insured-data",
    exact: true,
    name: "Proposal Details",
    component: CarProposalDetails,
    private: false,
  },
  {
    path: "/car/add-vehicle-details",
    exact: true,
    name: "Add Vehicle Details",
    component: AddVehicleDetails,
    private: false,
  },
  {
    path: "/car/owner-details",
    exact: true,
    name: "Owner Details",
    component: OwnerDetails,
    private: false,
  },
  {
    path: "/car/paynow-payment",
    exact: true,
    name: "Customer Summary",
    component: CarCustomerSummary,
    private: false,
  },
  {
    path: "/car/thank-you",
    exact: true,
    name: "Thank You",
    component: Thankyou,
    private: false,
  },
  {
    path: "/car/payment-page",
    exact: true,
    name: "Payment Page",
    component: CarPaymentPage,
    private: false,
  },
  {
    path: "/car/user-thank-you",
    exact: true,
    name: "Thankyou Page",
    component: CarThankYOuUser,
    private: false,
  },
  {
    path: "/car/payment-progress",
    exact: true,
    name: "Payment Progress",
    component: CarPaymentProgress,
    private: false,
  },
  {
    path: "/car/payment-failure",
    exact: true,
    name: "Payment Failure",
    component: CarPaymentFailure,
    private: false,
  },
  {
    path: "/car/user-payment-failure",
    exact: true,
    name: "Payment Failure",
    component: CarUserPaymentFailure,
    private: false,
  },
  {
    path: "/car/madeour-day",
    exact: true,
    name: "Made Our Day",
    component: CarMadeOurday,
    private: false,
  },
  {
    path: "/car/registration-details",
    exact: true,
    name: "Registration Details",
    component: RegistrationDetails,
    private: false,
  },

  {
    path: "/nstp-partner/thankyou-appointment",
    exact: true,
    name: "Nstp Book appointment Details",
    component: NstpBookAppointment,
    private: false,
  },
  {
    path: "/nstp-partner/thank-you-nstp-user",
    exact: true,
    name: "Nstp Thank you page user side",
    component: nstpCustomerThankYou,
    private: false,
  },
  {
    path: "/topup/renewal-details/:id/:year",
    exact: true,
    name: "Topup Renewal Details",
    component: TopupRenewalDetails,
    private: false,
  },
  {
    path: "/topup/portability-decline/",
    exact: true,
    name: "Topup Portability Decline",
    component: TopupPortDecline,
    private: false,
  },
  {
    // path: "/topup/renewal-edit-insurer-details/:id/:year/:type",
    path: "/topup/renewal-edit-insurer-details/:type",
    exact: true,
    name: "Topup Renewal Edit Insurer Details",
    component: TopupRenewalEditInsurerDetails,
    private: false,
  },
  {
    component: Error404,
    private: false,
    exact: false,
  },
];

export default routes;
