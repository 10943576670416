import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";

function EdlInputTypeDate({
  label,
  changeValue,
  name,
  dob,
  disable,
  futureDate,
  id,
  required,
  variant,
}) {
  return (
    <div className="custome-input-date">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id={id}
          format="dd/MM/yyyy"
          placeholder="DD/MM/YYYY"
          label={label}
          value={dob}
          onChange={changeValue}
          name={name}
          inputVariant={variant}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          disabled={disable}
          InputLabelProps={{ required }}
          keyboardIcon={<i />}
          disableFuture={futureDate}
          invalidDateMessage=""
          maxDateMessage="Future Dates not Allowed"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

EdlInputTypeDate.propTypes = {
  label: PropTypes.string.isRequired,
  changeValue: PropTypes.func,
  name: PropTypes.string,
  dob: PropTypes.string,
  disable: PropTypes.bool,
  futureDate: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.string,
};

export default EdlInputTypeDate;
