/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-fragments */
import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { AlertCircle } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tooltip: {
    fontSize: "0.8em",
  },
  icon: {
    display: "inline-block",
    marginLeft: "4px",
  },
});

const EdlTooltip = ({ text, placement, onClick }) => {
  const classes = useStyles();
  const [toolTipState, setToolTipState] = React.useState(false);

  return (
    <>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={text}
        placement={placement}
        onClick={onClick}
        arrow
        open={toolTipState}
      >
        {/* <i className="fa fa-info-circle" /> */}
        <AlertCircle
          size="18"
          color="#777"
          onMouseEnter={() => setToolTipState(true)}
          onMouseLeave={() => setToolTipState(false)}
          onClick={() => setToolTipState(true)}
          onBlur={() => setToolTipState(false)}
          className={classes.icon}
        />
      </Tooltip>
    </>
  );
};

EdlTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default EdlTooltip;
