import { all, takeEvery, put, call } from "redux-saga/effects";

import { CREATE_PROPOSAL, GET_QUOTE_DETAIL } from "../../action/types";
import {
  createProposalSuccess,
  createProposalFailure,
  getQuoteDetailSuccess,
  getQuoteDetailFailure,
} from "../../action";
import API from "../../../utils/axios";
import { setProposalID, getQuoteid } from "../../../utils/helper";

function* createProposalRequest(action) {
  try {
    const quoteId = getQuoteid();

    const res = yield API.post(
      `api/arogya-sanjeevani/quote/${quoteId}/proposal/`,
      action.payload.form
    );
    yield call(setProposalID, res.data.data.proposal_id);
    yield put(createProposalSuccess(res));
    yield call(action.payload.callback);
  } catch (error) {
    yield put(createProposalFailure(error));
  }
}

function* getQuoteDetailsRequest() {
  try {
    const quoteId = getQuoteid();

    const res = yield API.get(`api/arogya-sanjeevani/quote/${quoteId}/`);

    yield put(getQuoteDetailSuccess(res));
  } catch (error) {
    yield put(getQuoteDetailFailure(error));
  }
}

export function* watchCreateProposal() {
  yield takeEvery(CREATE_PROPOSAL, createProposalRequest);
}

export function* watchGetQuoteDetails() {
  yield takeEvery(GET_QUOTE_DETAIL, getQuoteDetailsRequest);
}

export default function* rootSaga() {
  yield all([watchCreateProposal()]);
  yield all([watchGetQuoteDetails()]);
}
