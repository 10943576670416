import {
  CREATE_CONTACT_DETAILS,
  CREATE_CONTACT_DETAILS_FAILURE,
  CREATE_CONTACT_DETAILS_SUCCESS,
} from "../../action/types";

const INIT_STATE = {
  contact_details: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_CONTACT_DETAILS:
      return { ...state, contact_details: action.payload };
    case CREATE_CONTACT_DETAILS_SUCCESS:
      return { ...state, contact_details: action.payload };
    case CREATE_CONTACT_DETAILS_FAILURE:
      return { ...state, contact_details: action.payload };
    default:
      return state;
  }
};
