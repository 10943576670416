import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ToastComponent = ({ open, handleClose, message, isSuccess = true }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={isSuccess ? "success" : "error"}
        style={{
          backgroundColor: isSuccess ? "var(--aqua-9)" : "var(--coral-4)",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

ToastComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  isSuccess: PropTypes.bool,
};

export default ToastComponent;
