import {
  GET_QUICK_SUMMARY,
  GET_QUICK_SUMMARY_SUCCESS,
  GET_QUICK_SUMMARY_FAILURE,
} from "../types";

export const getquickSummary = () => ({
  type: GET_QUICK_SUMMARY,
});

export const getquickSummarySuccess = (payload) => ({
  type: GET_QUICK_SUMMARY_SUCCESS,
  payload,
});

export const getquickSummaryFailure = (payload) => ({
  type: GET_QUICK_SUMMARY_FAILURE,
  payload,
});
