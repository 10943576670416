import CryptoJS from "crypto-js";

function getRandomHexString(length) {
  const characters = "0123456789abcdef";
  let result = "";
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export default function convertData(data) {
  const id = getRandomHexString(16);
  const iv = CryptoJS.enc.Utf8.parse(id);
  let encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY1),
    { iv, mode: CryptoJS.mode.CBC }
  );
  encrypted = encrypted.toString();
  return id + encrypted;
}
